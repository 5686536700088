import { Button } from '@mui/material';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import {Stack} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './cards.css';

const Hero = () => {

    const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
        main: '#000000',
        darker: '#053e85',
        fontWeight: 'bold',
        },
        neutral: {
        main: '#64748B',
        contrastText: '#fff',
        },
    },
    });

    return (
        <>
<ThemeProvider theme={theme}>
<br></br>
&nbsp;&nbsp;&nbsp;<Button size="small" sx={{fontSize: '0.91vmax'}} variant="contained" href="https://equalizer.exchange/swap?toToken=0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E" target="_blank" endIcon={<OpenInNewIcon />} > 
    Equalizer 
    </Button>

    <p style={{
                    paddingTop: -50,
                    marginTop: -50,
                    textAlign: 'top',
                    fontWeight: '900',
                    color: 'white',
                    fontSize: 24,
                    textShadow: '1px 1px #0a80c9',
                    webkitTextStrokeWidth: '1px',
                    webkitTextStrokeColor: '#0a80c9',
                    webkitFontSmoothing: 'antialiased',
                   }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p> 

{/*}    <Button variant="contained" href="https://wigoswap.io/swap?outputCurrency=0xb715F8DcE2F0E9b894c753711bd55eE3C04dcA4E" target="_blank" endIcon={<OpenInNewIcon />}>
    Wigoswap
                </Button> */}
</ThemeProvider>  
        <div style={{
        marginTop: 45,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '92%',
        height: '50vw',
        backgroundImage: "url('./images/GeXAEzvXoAA9tVP.jpg')",
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundColor: '#ceebfd',
        borderRadius: 15,
    }}>
        {/*backgroundImage: "url('./images/banner.png')",*/}
        <table border="0" style={{
            position: 'relative',
            width: '100%',
            height: '100%',
        }}>
            <tr>
                <td style={{
                    paddingLeft: 25,
                    fontSize: '220%',
                    color: 'white',
                    fontWeight: 'bold',
                    verticalAlign: 'top',                                       
                }}>
                  
                    </td><td></td></tr>
                <tr style={{
                    height: 100,
                }}><td style={{
                    paddingLeft: 25,
                    paddingBottom: 25
                }}>

                </td><td></td>
            </tr>
        </table>
    </div>
    </>

  );
  /*
    <div style={{
        borderBottom: '1'
    }}>
        <input type="button">Buy CONK</input>
        <font style={{
            fontSize: '200%',
            fontWeight: 'bold',
        }}>
        Fantom's Meme Coin
        </font>
    </div>
  backgroundColor: '#da575d',
        opacity: '70%',
        paddingLeft: 20,
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: '0',
        borderStyle: 'thin',
        borderColor: 'pink',

  */
};

export default Hero;